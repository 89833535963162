import React from 'react';
import { BiDollar, BiHide } from 'react-icons/bi';
import { BsPersonFill } from 'react-icons/bs';
import {
  FaBox,
  FaCalculator,
  FaFileInvoiceDollar,
  FaTruck,
} from 'react-icons/fa';
import { HiClipboardList } from 'react-icons/hi';
import { useEntradaNFE } from '../../EntradaNFEContext';
import {
  CardContainer,
  DescriptionContainer,
  MessageContainer,
  TypeContainer,
  TypeIcon,
} from './styles';

interface CardCriticasProps {
  type: number;
  title: string;
  message: string;
  show: boolean;
}

/**
 *
 * @param type
 * 0 - Capa
 * 1 - Item
 * 2 - Financeiro
 * 3 - Guia
 * 4 - ICMS
 * 5 - Frete
 * 6 - Fornecedor
 */

export const CardCritica: React.FC<CardCriticasProps> = ({
  type,
  title,
  message,
  show,
}) => {
  const { criticas, setCriticas } = useEntradaNFE();

  const getIcon = () => {
    switch (type) {
      // Item
      case 1: {
        return <FaBox />;
      }
      // Financeiro
      case 2: {
        return <BiDollar />;
      }
      // Guia
      case 3: {
        return <FaFileInvoiceDollar />;
      }
      // ICMS
      case 4: {
        return <FaCalculator />;
      }
      // Frete
      case 5: {
        return <FaTruck />;
      }
      // Fornecedor
      case 6: {
        return <BsPersonFill />;
      }
      // Capa
      default: {
        return <HiClipboardList />;
      }
    }
  };

  const getTitleType = () => {
    switch (type) {
      case 1: {
        return 'Item';
      }
      case 2: {
        return 'Financeiro';
      }
      case 3: {
        return 'Guia';
      }
      case 4: {
        return 'ICMS';
      }
      case 5: {
        return 'Frete';
      }
      case 6: {
        return 'Fornecedor';
      }
      default: {
        return 'Capa';
      }
    }
  };

  const formatMessage = () => {
    const splittedMessage = message.split(`\n`);
    const formatedMessage = splittedMessage.map((part) => {
      return <span>{part}</span>;
    });
    return formatedMessage;
  };

  const handleHideCard = () => {
    const newCriticas = criticas.map((critica) => {
      if (critica.title === title) {
        critica.show = false;
      }
      return critica;
    });
    setCriticas(newCriticas);
  };

  return (
    <>
      {show && (
        <CardContainer>
          <MessageContainer>
            <TypeContainer>
              <span>{getTitleType()}</span>
              <TypeIcon>{getIcon()}</TypeIcon>
            </TypeContainer>
            <DescriptionContainer>
              <strong>{title}</strong>
              {formatMessage()}
            </DescriptionContainer>
          </MessageContainer>
          <BiHide
            className="hide-action"
            size={24}
            onClick={() => handleHideCard()}
          />
        </CardContainer>
      )}
    </>
  );
};
